import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

/**
 * @todo UI design use outlined
 */
const LinkedinProxySettings = () => {
  const { control } = useFormContext();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ width: "100%" }}
    >
      <Controller
        name="protocol"
        control={control}
        defaultValue="http"
        render={({ onChange, value, ...props }) => (
          <FormControl variant="standard" fullWidth>
            <InputLabel id="protocol-label">Protocol</InputLabel>
            <Select
              labelId="protocol-label"
              id="protocol"
              value={value}
              onChange={onChange}
              {...props}
              label="Protocol"
            >
              <MenuItem value="socks5">SOCKS5</MenuItem>
              <MenuItem value="https">HTTPS</MenuItem>
              <MenuItem value="http">HTTP</MenuItem>
            </Select>
          </FormControl>
        )}
      />

      <Controller
        name="domain"
        control={control}
        defaultValue=""
        render={({ onChange, value, ...props }) => (
          <TextField
            id="domain"
            label="Domain"
            variant="standard"
            placeholder="proxy.com / 111.111.111.111"
            fullWidth
            value={value}
            onChange={onChange}
            {...props}
          />
        )}
      />

      <Controller
        name="port"
        control={control}
        defaultValue={0}
        render={({ onChange, value, ...props }) => (
          <TextField
            id="port"
            label="Port"
            variant="standard"
            type="number"
            placeholder="85"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={value}
            onChange={onChange}
            {...props}
          />
        )}
      />

      <Controller
        name="usernameProxy"
        control={control}
        defaultValue=""
        render={({ onChange, value, ...props }) => (
          <TextField
            id="usernameProxy"
            label="Username"
            variant="standard"
            fullWidth
            value={value}
            onChange={onChange}
            {...props}
          />
        )}
      />

      <Controller
        name="passwordProxy"
        control={control}
        defaultValue=""
        render={({ onChange, value, ...props }) => (
          <TextField
            id="passwordProxy"
            label="Password"
            variant="standard"
            type="password"
            fullWidth
            value={value}
            onChange={onChange}
            {...props}
          />
        )}
      />
    </Stack>
  );
};

export default LinkedinProxySettings;
